import React, { useState, useRef } from 'react';
import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

// Utility to check if it's a video
const isVideo = (url) => /\.(mp4|webm|ogg)$/i.test(url);

const MediaCard = ({ title, description, videoUrl, onPlay, videoRef }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(true);
  const mediaElement = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      mediaElement.current.pause();
    } else {
      onPlay();
      mediaElement.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
    if (mediaElement.current) {
      mediaElement.current.muted = !isMuted;
    }
  };

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '15px',
        overflow: 'hidden',
        boxShadow: '0 8px 16px rgba(0, 255, 255, 0.4)',
        backgroundColor: '#0d0d0d',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        '&:hover': {
          transform: 'scale(1.03)',
          boxShadow: '0 12px 24px rgba(0, 255, 255, 0.7)',
        },
      }}
    >
      {/* Media Section */}
      <Box
        sx={{
          position: 'relative',
          height: 300,
          backgroundColor: '#111',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isVideo(videoUrl) ? (
          <video
            ref={(el) => {
              mediaElement.current = el;
              videoRef(el); // Ensure the parent can control this ref
            }}
            src={videoUrl}
            muted={isMuted}
            loop
            onPlay={onPlay}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              filter: isPlaying ? 'grayscale(0%)' : 'grayscale(60%)',
              transition: 'filter 0.5s ease',
            }}
          />
        ) : (
          <img
            src={videoUrl}
            alt={title}
            loading="lazy"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              filter: 'grayscale(50%) brightness(85%)',
              transition: 'filter 0.5s ease',
            }}
          />
        )}

        {isVideo(videoUrl) && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 10,
              left: 10,
              display: 'flex',
              gap: 1,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              borderRadius: '8px',
              padding: '5px 10px',
            }}
          >
            <Tooltip title={isPlaying ? 'Pause' : 'Play'}>
              <IconButton onClick={handlePlayPause} sx={{ color: '#00ffea' }}>
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </IconButton>
            </Tooltip>

            <Tooltip title={isMuted ? 'Unmute' : 'Mute'}>
              <IconButton onClick={handleMuteToggle} sx={{ color: '#00ffea' }}>
                {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* Title and Description */}
      <Box
        sx={{
          padding: '1em',
          color: '#00ffea',
          fontFamily: 'monospace',
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {title}
        </Typography>
        <Typography variant="body2" sx={{ color: '#ccc', mt: 1 }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default MediaCard;
