import React, { useState, useEffect, useCallback } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DropdownMenu from './DropdownMenu';
import logo from '../assets/neal-frazier-tech-logo.png';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css'; // Custom CSS for typewriter and glitch effects

// Styled Components
const Logo = styled('img')({
  width: '50px',
  height: 'auto',
  marginLeft: '20px',
  marginTop: 7,
});

const CustomMenuIcon = styled('img')({
  width: '24px',
  height: '24px',
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '64px',
  backdropFilter: 'blur(10px)',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  boxShadow: '0px 0px 10px rgba(255, 255, 231, 0.5)',
  position: 'fixed',
  top: '0',
  left: '50%',
  transform: 'translateX(-50%)',
  transition: 'top 0.3s',
  borderRadius: '60px',
  width: '90%',
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
}));

const Navbar = () => {
  const [scrollDirection, setScrollDirection] = useState('up');
  const [prevScrollY, setPrevScrollY] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    setScrollDirection(currentScrollY > prevScrollY ? 'down' : 'up');
    setPrevScrollY(currentScrollY);
  }, [prevScrollY]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <StyledAppBar sx={{ top: scrollDirection === 'down' ? '-84px' : '20px' }}>
      <Toolbar sx={{ width: '100%', position: 'relative' }}>
        {/* Logo aligned to the left */}
        <Link to="/">
          <Logo src={logo} alt="Logo" />
        </Link>

        {/* Centered Typography with Typewriter and Glitch Effect */}
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: { xl: '30px', xs: '15px' },
            fontWeight: 'bold',
            overflow: 'hidden',
          }}
        >
          <Typography
            className="typewriter-glitch"
            sx={{
              color: '#ffffff',
              fontSize: {xs: "1rem", md: "3rem"},
              fontFamily: 'Future-Tech-Font',
            }}
          >
            Neal Frazier Tech
          </Typography>
        </Box>

        {/* Custom Hamburger Icon */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          onClick={handleMenuClick}
          sx={{ marginLeft: 'auto', marginRight: '10px' }}
        >
          {openMenu ? <CloseIcon /> : <CustomMenuIcon src="/assets/blue-hamburger.png" alt="Menu" />}
        </IconButton>

        {/* Dropdown Menu */}
        <DropdownMenu open={openMenu} onClose={handleMenuClose} />
      </Toolbar>
    </StyledAppBar>
  );
};

export default Navbar;
