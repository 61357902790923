import React, { useRef } from 'react';
import { Box, Grid } from '@mui/material';
import MediaCard from '../components/MediaCard';
import MediaCardParallaxScene from '../components/MediaCardParallaxScene';
import PsychedelicStarScene from '../components/PsychedelicStarScene';
import HeroSection from '../components/HeroSection';
import { Helmet } from 'react-helmet-async';

const videoData = [
  { title: "Psychonaut Valley By Neal Frazier Tech", description: "Psychedelic Art Generated By A Local LLM Model by Neal Frazier Tech", videoUrl: "/assets/media-page-assets/1.png" },
  { title: "Is This Heaven By Neal Frazier Tech", description: "Vibrant Psychedelic Art Created By A Local AI LLM Model By Neal Frazier Tech", videoUrl: "/assets/media-page-assets/2.png" },
  { title: "See Between The Lines, Taste Between The Colors By Neal Frazier Tech", description: "Mesmerizing Psychedelic Art Made By A Local AI LLM Model By Neal Frazier Tech", videoUrl: "/assets/media-page-assets/3.png" },
  { title: "Stand Alone To Grow Strong By Neal Frazier Tech", description: "Cyberpunk Environment Created By A Local AI Model By Neal Frazier Tech", videoUrl: "/assets/media-page-assets/4.png" },
  { title: "Splatter By Neal Frazier Tech", description: "Abstract Digital Art Created With A Custom Python Script by Neal Frazier Tech and GIMP Software", videoUrl: "/assets/media-page-assets/5.webp" },
  { title: "The Kid By Neal Frazier Tech", description: "Anime Figure Created By A Local AI LLM Model BY Neal Frazier Tech", videoUrl: "/assets/media-page-assets/6.png" },
  { title: "Lost In Space By Neal Frazier Tech", description: "Psychonaut In Space Video Generated By A Local AI LLM By Neal Frazier Tech", videoUrl: "/assets/media-page-assets/1.mp4" },
  { title: "Geometrical Orbits By Neal Frazier Tech", description: "3D Video Scene Create With Three JS By Neal Frazier Tech", videoUrl: "/assets/media-page-assets/2.mp4", },
  { title: "Overthinker By Neal Frazier Tech", description: "Trippy Video Generated Local AI LLM Model And Edited With Shotcut By Neal Frazier Tech", videoUrl: "/assets/media-page-assets/3.webm", },
  { title: "A Mesmerizing Journey By Neal Frazier Tech", description: "Psychedelic Visual Created By A Local AI LLM Model And Edited With Shotcut By Neal Frazier Tech", videoUrl: "/assets/media-page-assets/4.webm"},
];

const MediaPage = () => {
  // Store refs to all video elements to control playback
  const videoRefs = useRef([]);

  const handlePlay = (index) => {
    // Pause all videos except the currently playing one
    videoRefs.current.forEach((video, i) => {
      if (video && i !== index) {
        video.pause();
      }
    });
  };

  return (
    <>
    <Helmet>
        <title>Media Gallery | Neal Frazier Tech</title>
        <meta
          name="description"
          content="Explore a curated selection of videos, music, and tech content in the Neal Frazier Tech media gallery. Immerse yourself in a unique blend of visual and musical experiences."
        />
        <meta
          name="keywords"
          content="Neal Frazier Tech, Media Gallery, Videos, Music, Tech Content, ASAP Rocky, Kid Cudi, JPEGMAFIA, Virginia Beach, technology media"
        />
        <meta property="og:title" content="Media Gallery | Neal Frazier Tech" />
        <meta
          property="og:description"
          content="Dive into Neal Frazier Tech's exclusive media page, featuring a psychedelic gallery of videos and music for tech enthusiasts."
        />
        <meta property="og:image" content="/NealFrazierTech.png" />
        <meta property="og:url" content="https://nealfrazier.tech/media" />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Media Gallery | Neal Frazier Tech" />
        <meta property="twitter:description" content="Discover a unique media experience with videos, music, and tech content curated by Neal Frazier Tech." />
        <meta property="twitter:image" content="/NealFrazierTech.png" />
      </Helmet>
    <HeroSection />
    <MediaCardParallaxScene />
        <Box
      sx={{
        backgroundColor: '#000000',
        minHeight: '100vh',
        padding: '100px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        position: 'relative',
        '::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'radial-gradient(circle, #00ffea, #121212)',
          opacity: 0.1,
          zIndex: -1,
        },
      }}
    >
     
      {/* Glitch Background Effect */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: 'url(/path-to-glitch-background.png)',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          opacity: 0.07,
          zIndex: -2,
          animation: 'glitchBg 3s infinite linear',
          '@keyframes glitchBg': {
            '0%': { transform: 'translate(0px, 0px)' },
            '25%': { transform: 'translate(2px, -2px)' },
            '50%': { transform: 'translate(-2px, 2px)' },
            '75%': { transform: 'translate(2px, 2px)' },
            '100%': { transform: 'translate(0px, 0px)' },
          },
        }}
      />

      {/* Media Cards */}
      <Grid container spacing={3} justifyContent="center" alignItems="center" sx={{ maxWidth: 600 }}>
        {videoData.map((item, index) => (
          <Grid item key={index} xs={12}>
            <MediaCard
              title={item.title}
              description={item.description}
              videoUrl={item.videoUrl}
              onPlay={() => handlePlay(index)}
              videoRef={(el) => (videoRefs.current[index] = el)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
    <PsychedelicStarScene />
    </>

  );
};

export default MediaPage;
